/*@import "~bootstrap/scss/bootstrap";*/
@import "../../node_modules/bootstrap/scss/bootstrap";

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  // padding-top: 3rem;
  // padding-bottom: 3rem;
  color: #5a5a5a;
  position: relative;
  min-height: 100vh;
}

.main{
  padding-bottom: 2.5rem;
}


/**Footer**/
/*----------------------------*/
.lead{
  font-size: 1.25rem;
}

.lead ul li {
  color: #ffffffff;
  font-size: 16px;
}

/* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

/* MARKETING CONTENT
  -------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
/* rtl:end:ignore */

/* Featurettes
  ------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

.featurette-divider-half {
  margin: 3rem auto;
  // width: 50%;
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -0.05rem;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
  -------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
    margin-bottom: 3rem;
  }
}

.icon-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E")
    no-repeat center center / 100% auto;
}

/**Nav bar **/
.navbar-dark {
  background-color: "#4c0bce";
}

@media (max-width: 360px) {
  .navbar-dark {
    width: 360px;
  }
}

  .yarl__slide_title, .yarl__slide_description{
    --yarl__toolbar_width: 100 !important;
    text-align: center !important;
  }

.rdrCalendarWrapper {
  width: 100%;
  font-size: 0.9rem;
}

.all-events:hover {
  cursor: pointer;
}

.upcoming-event:hover {
  cursor: pointer;
  background-color: rgb(223, 226, 228);
}

.card-list-item {
  border-radius: 0px;
  border: 0px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

span.rdrDayStartPreview,
span.rdrDayEndPreview {
  color: transparent !important;
}

button.rdrDayHovered,
button.rdrDay {
  color: transparent !important;
}

.adm-reqs{
  list-style: none;
  counter-reset: steps;
}

.adm-reqs li {
  counter-increment: steps;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.adm-reqs li::before {
  content: counter(steps);
  margin-right: 0.5rem;
  // background: #ff6f00;
  background: var(--primary-color);
  color: white;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  line-height: 1.2em;
}

.container-form{
  border: 4px solid var(--primary-color);
  margin-top: 1.5rem;
  padding: 2em;
}

.container-form input, .container-form select {
  border-radius: 0px;
}

.container-form label{
  font-weight: bolder;
  margin-bottom: 15px;
}

.form-control:focus{
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 6px 0rem var(--primary-color)

}

footer{
  background-color: #202020;
  width: 100%;
  padding: 2rem 4rem;
}

.footer-wrapper{
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2.5rem;
}

.footer-socials span a {
  text-decoration: none;
  color: #ffffffff;
  padding-right: 10px;
  font-size: 30px;
}

.footer-socials span a:hover {
  color: var(--primary-color);
}

.footer-nav a{
  padding-left: 0 !important;
  // color: #ffffff8c;
  color: #ffffffff;
}

.copyright{
  width: 100%;
  display: inline-block;
  padding: 0.6rem 2rem;
  background-color: #001529;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}

.footer-nav a:hover{
  color: var(--primary-color);
  text-decoration: underline;
}

a.nav-link a{
  text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.5)';
}

/*@import "~react-image-gallery/styles/scss/image-gallery.scss";*/
@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";


